import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectFade } from "swiper"

const query = graphql`
  {
    allContentfulAboutMe {
      nodes {
        slideshow {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`

const Banner = () => {
  const data = useStaticQuery(query)
  const images = data?.allContentfulAboutMe?.nodes?.[0]?.slideshow ?? []

  return (
    <div className="relative h-full min-h-screen flex items-center justify-center">
      <Swiper
        modules={[Autoplay, EffectFade]}
        loop
        centeredSlides
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        effect="fade"
        className="absolute w-full h-full z-0"
      >
        {images.map((image, index) => (
          <SwiperSlide key={`banner-background-image-${index}`}>
            <GatsbyImage
              loading="eager"
              image={getImage(image)}
              alt="background image"
              className="h-full"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-10"></div>
      <div className="absolute w-full h-full">
        <div className="absolute uppercase tracking-wider transform -rotate-90 top-1/2 -left-28 hidden md:flex items-center">
          <p className="text-[14px]">design makes your life better</p>
          <div className="w-24 h-[0.5px] bg-white animate-move-left-right"></div>
        </div>
      </div>
      <div className="relative text-white w-full h-full">
        <div className="flex justify-center items-center space-x-3 md:space-x-6 text-center text-xl md:text-3xl font-extralight">
          <div>Architecture</div>
          <div className="text-xs md:text-xl">&#9679;</div>
          <div>Interior</div>
          <div className="text-xs md:text-xl">&#9679;</div>
          <div>Planning</div>
        </div>
        <div className="relative text-center mt-10">
          <button
            className="relative text-white border border-white active:border-transparent hover:border-transparent active:bg-[#1A1A1A] hover:bg-[#1A1A1A] px-10 py-4 uppercase text-sm font-semibold transition-colors duration-500 group"
            onClick={() => navigate("/projects")}
          >
            <div className="absolute h-[calc(100%+2px)] w-[0.5px] group-hover:h-0 bg-white top-2.5 -right-1.5 transition-all duration-500"></div>
            <div className="absolute h-[1px] w-[calc(100%+2px)] group-hover:w-0 bg-white -bottom-1.5 left-2.5 transition-all duration-500"></div>
            <span className="relative">view projects</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Banner
