import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Banner from "../components/HomePage/Banner"
import News from "../components/HomePage/News"
import Contact from "../components/Contact"

const HomePage = () => {
  return (
    <>
      <GatsbySeo
        title="Beshaus Architecture Studio"
        titleTemplate="%s | BESHAUS"
      />
      <Banner />
      <News />
      <Contact />
    </>
  )
}

export default HomePage
