import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  LocationMarkerIcon,
  InboxIcon,
  PhoneIcon,
} from "@heroicons/react/outline"

const query = graphql`
  {
    contentfulAboutMe {
      officeName
      location
      gmail
      tel
    }
  }
`

const Contact = () => {
  const data = useStaticQuery(query)
  const partialAbout = data?.contentfulAboutMe

  return (
    <section className="bg-black text-white border-b border-t border-white border-opacity-20">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="w-full h-full min-h-[450px]">
          <iframe
            title="beshaus location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.613105250955!2d105.86897061493302!3d21.048161185987915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab94e2bc30ad%3A0x4c7d1f9cc28c54fa!2sV%C4%83n%20ph%C3%B2ng%20ki%E1%BA%BFn%20tr%C3%BAc%20Beshaus%20-%20Architecture%20Studio!5e0!3m2!1sen!2s!4v1628704228491!5m2!1sen!2s"
            width="100%"
            height="100%"
            style={{
              border: 0,
              filter:
                "brightness(100%) contrast(100%) saturate(0%) blur(0) hue-rotate(0deg)",
            }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="flex items-center">
          <div className="px-6 md:px-20 py-10">
            <h3 className="text-xl uppercase">Contacts</h3>
            <div className="mt-8 space-y-3 font-raleway text-beshaus-body-text">
              <p className="flex items-center space-x-2">
                {partialAbout?.officeName}
              </p>
              <p className="flex items-center space-x-2">
                <LocationMarkerIcon className="w-5 h-5" />
                <span>{partialAbout?.location}</span>
              </p>
              <p className="flex items-center space-x-2">
                <InboxIcon className="w-5 h-5" />
                <span>{partialAbout?.gmail}</span>
              </p>
              <p className="flex items-center space-x-2">
                <PhoneIcon className="w-5 h-5" />
                <span>{partialAbout?.tel}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
