import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Heading from "../Heading"

const query = graphql`
  {
    allContentfulNews(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        id
        url
        siteName
        updatedAt(formatString: "DD/MM/YYYY")
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 400)
        }
      }
    }
  }
`

const News = () => {
  const data = useStaticQuery(query)
  const allNews = data?.allContentfulNews?.nodes ?? []

  return (
    <section className=" bg-[#0A0A0A] relative text-white">
      <div className="container max-w-7xl mx-auto">
        <div className="px-6 py-20 relative">
          <Heading preHeading="news" heading="Read Our Latest News" />
          <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2">
            {allNews.map(({ id, url, siteName, image, updatedAt }) => (
              <div
                key={id}
                className="p-4 border border-opacity-25 hover:border-opacity-80 transition-all duration-500 ease-in-out"
              >
                <div className="h-48 w-full relative">
                  <div className="absolute top-0 left-0 w-full h-full">
                    <GatsbyImage
                      image={getImage(image)}
                      className="w-full h-full"
                      alt="news thumbnail"
                    />
                  </div>
                </div>
                <div className="my-4 flex flex-col space-y-2">
                  <span className="text-sm text-gray-400">{updatedAt}</span>
                  <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-2xl"
                  >
                    {siteName}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default News
